import React from "react";

function LowerFuelConsumption() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="53"
            height="53"
            fill="none"
            viewBox="0 0 53 53"
        >
            <rect
                width="53"
                height="53"
                fill="url(#paint0_linear_2_708)"
                rx="10"
            ></rect>
            <path
                fill="#000"
                d="M24.467 14.371h-4.029a.538.538 0 00-.536.537v.91c-.559.017-1.08.184-1.522.467l3.343 3.343h10.113l3.343-3.343a2.97 2.97 0 00-1.432-.462v-1.08A1.761 1.761 0 0031.982 13h-3.948c-.96 0-1.748.773-1.764 1.738v1.073h-1.267v-.903a.535.535 0 00-.536-.537zm2.875.367a.693.693 0 01.692-.665h3.948c.384 0 .688.3.692.682v1.055h-5.332v-1.072zM36.558 36.827V18.804c0-.67-.224-1.287-.595-1.785l-3.368 3.368v14.859l3.367 3.367c.372-.5.596-1.116.596-1.786zM31.522 34.932v-14.23h-9.484v14.23h9.484zm-5.204-10.535c.192-.327.73-.327.924 0 .336.57 2.011 3.473 2.011 4.63a2.476 2.476 0 01-2.473 2.474 2.476 2.476 0 01-2.474-2.473c0-1.158 1.676-4.06 2.012-4.631zM17 18.804v18.023c0 .67.224 1.288.596 1.787l3.369-3.368v-14.86l-3.369-3.368A2.974 2.974 0 0017 18.804zM33.57 39.82c.592 0 1.143-.175 1.608-.474l-3.342-3.341H21.723l-3.341 3.34a2.974 2.974 0 001.611.475H33.57z"
            ></path>
            <defs>
                <linearGradient
                    id="paint0_linear_2_708"
                    x1="26.5"
                    x2="26.5"
                    y1="0"
                    y2="53"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFD79A"></stop>
                    <stop offset="1" stopColor="#FCBE60"></stop>
                </linearGradient>
            </defs>
        </svg>
    );
}

export default LowerFuelConsumption;
