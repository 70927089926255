import React, { ReactElement } from "react";
import styles from "./styles.module.scss";
import ImproveMaintenance from "./icons/improveMaintenance";
import DriveSafety from "./icons/driveSafety";
import LowerFuelConsumption from "./icons/lowerFuelConsumption";
import UpdateInsight from "./icons/updateInsight";
import Image from "next/image";

const benefitsList = [
    {
        title: "Improve Driver Safety",
        description:
            "Driver safety is of paramount importance to fleet managers, and their performance and behavior are one of the critical focuses of a sound fleet management system.",
        icon: <DriveSafety />,
    },
    {
        title: "Improve Vehicle Maintenance",
        description:
            "Fleet maintenance and vehicle life cycles can often be areas that put a heavy strain on a business’s finances.",
        icon: <ImproveMaintenance />,
    },
    {
        title: "Lower Fuel Consumption",
        description:
            "Ever-fluctuating fuel prices make tracking and budgeting for fuel costs a challenge.",
        icon: <LowerFuelConsumption />,
    },
    {
        title: "Updates and Insights",
        description:
            "GPS vehicle tracking software allows fleet managers to track their fleet in near real-time and redirect vehicles when necessary.",
        icon: <UpdateInsight />,
    },
];

export default function Benefits(): ReactElement {
    return (
        <div
            className={`flex flex-col xl:flex-row flex-nowrap items-center justify-between mb-12 max-w-7xl px-4 mx-auto ${styles["DoYouKnowWrapper"]}`}
        >
            <div className="w-full md:w-3/5 ">
                <div
                    className={` font-bold mb-8 text-3xl xl:text-4xl pt-11 md:pt-24 mb-12 ${styles["title"]}`}
                >
                    Benefits of Fleet Tracking
                </div>

                {benefitsList.map((benefit) => (
                    <div key={benefit.title}>
                        <div className={`flex items-start mb-5`}>
                            <div className={`w-16 mr-8`}>{benefit.icon}</div>
                            <div>
                                <div
                                    className={`font-bold  text-base xl:text-lg ${styles["title2"]}`}
                                >
                                    {benefit.title}
                                </div>
                                <div
                                    className={`text-sm mb-6 ${styles["brief"]}`}
                                >
                                    {benefit.description}
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <div className="relative z-10 w-[230px] h-[216px] lg:w-[460px] lg:h-[432px]">
                <Image
                    src="/fleettrackingcosts/assets/images/bottom_image.png"
                    alt="Benefits of Fleet Tracking"
                    layout="fill"
                />
            </div>
        </div>
    );
}
