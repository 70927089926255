import React from "react";

function UpdateInsight() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="53"
            height="53"
            fill="none"
            viewBox="0 0 53 53"
        >
            <rect
                width="53"
                height="53"
                fill="url(#paint0_linear_2_709)"
                rx="10"
            ></rect>
            <path
                fill="#000"
                fillRule="evenodd"
                d="M16.704 14h19.592A2.712 2.712 0 0139 16.704v19.592A2.712 2.712 0 0136.296 39H16.704A2.712 2.712 0 0114 36.296V16.704A2.712 2.712 0 0116.704 14zm14.56 9.444c1.169 1.432-1.008 3.21-2.178 1.779l-1.178-1.442v9.145c0 1.853-2.816 1.853-2.816 0V24.04l-1.174 1.444c-1.166 1.436-3.35-.337-2.183-1.773l3.604-4.434c.608-.747 1.822-.852 2.567.06l3.357 4.108z"
                clipRule="evenodd"
            ></path>
            <defs>
                <linearGradient
                    id="paint0_linear_2_709"
                    x1="26.5"
                    x2="26.5"
                    y1="0"
                    y2="53"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFD79A"></stop>
                    <stop offset="1" stopColor="#FCBE60"></stop>
                </linearGradient>
            </defs>
        </svg>
    );
}

export default UpdateInsight;
