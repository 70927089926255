import React from "react";

function ImproveMaintenance() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="53"
            height="53"
            fill="none"
            viewBox="0 0 53 53"
        >
            <rect
                width="53"
                height="53"
                fill="url(#paint0_linear_2_707)"
                rx="10"
            ></rect>
            <path
                fill="#000"
                fillRule="evenodd"
                d="M39.4 22.3l-1.5-6.9s-.3-2.4-3-2.4H17.8s-2.1 0-2.7 2.4l-1.5 7.2-.6 3v13.5a.9.9 0 00.9.9h4.2a.9.9 0 00.9-.9V37h15v2.1a.9.9 0 00.9.9h4.2a.9.9 0 00.9-.9V25.6s-.337-1.984-.6-3.3zM18.25 32.5a2.25 2.25 0 110-4.5 2.25 2.25 0 010 4.5zm16.5 0a2.25 2.25 0 110-4.5 2.25 2.25 0 010 4.5zm-19.05-9l1.5-7.5s.3-.9.9-.9h16.5s1.071-.043 1.2.9l1.5 7.5H15.7z"
                clipRule="evenodd"
            ></path>
            <defs>
                <linearGradient
                    id="paint0_linear_2_707"
                    x1="26.5"
                    x2="26.5"
                    y1="0"
                    y2="53"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFD79A"></stop>
                    <stop offset="1" stopColor="#FCBE60"></stop>
                </linearGradient>
            </defs>
        </svg>
    );
}

export default ImproveMaintenance;
